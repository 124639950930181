<template>
  <div class="monitor">
    <div class="monitor-header" v-if="mapPageParams.showHeader || mapPageParams.showMapTrackDetailButton">
      <!-- 地图工具条 -->
      <map-tool :params="mapPageParams" @mapToolClick="handleMapToolClick" />
    </div>
    <div
      class="monitor-center"
      :class="[
        mapPageParams.showHeader
          ? isFullscreen
            ? 'with-header-fullscreen'
            : 'with-header'
          : isFullscreen
            ? 'no-header-fullscreen'
            : 'no-header'
      ]"
    >
      <!-- 地图页面 -->
      <map-page ref="mapRef" :params="mapPageParams" @mapPageClick="handleMapPageClick" />
    </div>
    <!--业务组件：左侧弹窗:根据业务需要注入 -->
    <slot name="left" />
    <!--业务组件： 右侧弹窗：根据业务需要注入 -->
    <slot name="right" />
  </div>
</template>
<script>
import MapTool from '../monitorComponents/maptool'
import MapPage from '../monitorComponents/mappage'
export default {
  name: 'MonitorTemplate',
  components: { MapTool, MapPage },
  props: {
    // 是否全屏
    isFullscreen: {
      type: Boolean,
      default: false
    },
    // 地图组件入参
    mapPageParams: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    console.log('showHeader', this.mapPageParams.showHeader)
  },

  data() {
    return {}
  },
  methods: {
    // 事件名称与事件参数
    handleMapPageClick(eventName, eventArgs) {
      this.$emit('mapPageClick', eventName, eventArgs)
    },
    // 工具栏点击事件
    handleMapToolClick(eventName, eventArgs) {
      this.$emit('mapToolClick', eventName, eventArgs)
    },
    // 地图轨迹播放
    mapTrackPlay(status, oldStatus) {
      // 转发轨迹播放
      this.$refs.mapRef.mapTrackPlay(status, oldStatus)
    },
    // 地图轨迹播放跳转
    mapTrackPlayJumpTo(val, status) {
      // 转发
      this.$refs.mapRef.mapTrackPlayJumpTo(val, status)
    },
    // 地图轨迹播放暂停
    mapTrackPlayPause() {
      // 转发
      this.$refs.mapRef.mapTrackPlayPause()
    },
    // 地图轨迹播放停止
    mapTrackPlayStop() {
      // 转发
      this.$refs.mapRef.mapTrackPlayStop()
    },
    // 设置地图轨迹播放速度
    mapSetTrackPlaySpeed(val, isRunning) {
      this.$refs.mapRef.mapSetTrackPlaySpeed(val, isRunning)
    },
    // 设置同步播放视频
    mapSetSyncVideo(val) {
      this.$refs.mapRef.mapSetSyncVideo(val)
    },
    // 弹窗显示物体信息，如果selectedId不为空，则显示，为空则不显示
    mapShowObjectInfo(selectedId, isSameList) {
      // 转发
      this.$refs.mapRef.mapShowObjectInfo(selectedId, isSameList)
    },
    // 获取坐标地址
    mapGetAddressByPoint(lng, lat, fn) {
      this.$refs.mapRef.mapGetAddressByPoint(lng, lat, fn)
    },
    // 更新对象节点集
    mapUpdateObjectPoints(newVal) {
      this.$refs.mapRef.mapUpdateObjectPoints(newVal)
    },
    // 更新围栏
    mapDrawFence(newVal, isRedraw) {
      this.$refs.mapRef.mapDrawFence(newVal, isRedraw)
    },
    // 设置显示信号强弱
    mapSetShowSignal(val) {
      this.$refs.mapRef.mapSetShowSignal(val)
    }
  }
}
</script>

<style lang="less" scoped>
.monitor {
  margin: -15px -13px -10px -12px;
  .monitor-header {
    position: relative;
    height: 30px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
  }
  .monitor-center {
    width: 100%;
  }
  .with-header {
    height: calc(100vh - 140px);
  }
  .no-header {
    height: calc(100vh - 110px);
  }
  .with-header-fullscreen {
    height: calc(100vh - 30px);
  }
  .no-header-fullscreen {
    height: calc(100vh);
  }
}
</style>
