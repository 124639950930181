var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rtc-screen",
      staticStyle: {
        height: "100%",
        display: "flex",
        background: "#000",
        "justify-content": "center",
      },
      style: _vm.type !== "main" ? " align-items: center" : "",
    },
    [
      _vm.videoType === "brtc"
        ? _c("div", {
            class: _vm.rotateClass,
            style: _vm.rotateStyle,
            attrs: { id: _vm.brtcId },
          })
        : _c("revo-video-player", {
            attrs: {
              vehicleId: _vm.vehicleId,
              vehicleCameraDeviceInfo: _vm.rtcInfo,
              videoType: "rtc",
              isLive: true,
              showScreenBtn: true,
            },
            on: { handleReady: _vm.handleReady, handleError: _vm.handleError },
          }),
      _vm.type === "main"
        ? _c(
            "div",
            { staticClass: "ctrl-container", attrs: { id: "ctrlContainer" } },
            [
              _c("div", { staticClass: "ctrl-left" }, [
                _c("div", { staticClass: "ctrl-info" }, [
                  _c("div", { staticClass: "speed" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../../../assets/images/control/bg-speed.png"),
                        height: "100%",
                      },
                    }),
                    _c("div", { staticClass: "speed-over" }, [
                      _c("span", { staticClass: "speed-text speed-media" }, [
                        _vm._v(_vm._s(_vm.runningSpeed)),
                      ]),
                      _c(
                        "span",
                        { staticClass: "speed-label speed-label-media" },
                        [_vm._v("速度(KM/H)")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "rotate" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../../../assets/images/control/bg-direction.png"),
                        height: "100%",
                      },
                    }),
                    _c("div", { staticClass: "rotate-over" }, [
                      _c("img", {
                        staticClass: "rotate-arrow",
                        style: {
                          transform: "rotate(" + _vm.locationHeading + "deg)",
                        },
                        attrs: {
                          src: require("../../../../../../assets/images/control/ico-arrow.png"),
                        },
                      }),
                      _c("span", { staticClass: "rotate-text rotate-media" }, [
                        _vm._v(_vm._s(_vm.locationHeading) + "°"),
                      ]),
                      _c(
                        "span",
                        { staticClass: "rotate-label rotate-label-media" },
                        [_vm._v("方向" + _vm._s(_vm.locationHeadingLRStr))]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-content" }, [
                    _c(
                      "div",
                      { staticClass: "soc", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v("电量：" + _vm._s(_vm.currentSoc) + "%"),
                        ]),
                        _c("img", {
                          style: {
                            width: _vm.currentSoc + "%",
                            height: "14px",
                            marginTop: "4px",
                          },
                          attrs: {
                            src: require("../../../../../../assets/images/control/bg-remainingPower.png"),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "brake", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v(
                            "刹车情况：" +
                              _vm._s(_vm.cmd.brake ? "刹车中" : "未刹车")
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "state", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v("作业状态： " + _vm._s(_vm.workStatus)),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "ctrl-center" }, [
                _c("div", { staticClass: "ctrl-btn" }, [
                  _c("div", { staticClass: "btn-list" }, [
                    _c("div", { staticClass: "row-1" }, [
                      _c("div", { staticClass: "col-1" }, [
                        _c("div", { staticClass: "btn-s" }, [
                          _c("img", {
                            staticClass: "ctrl-btn-left",
                            attrs: { src: _vm.getImgSrc("btn-a", _vm.leftBtn) },
                          }),
                        ]),
                        _c("div", { staticClass: "btn-v" }, [
                          _c("img", {
                            staticClass: "ctrl-w",
                            attrs: {
                              src: _vm.getImgSrc("btn-w", _vm.upGearBtn),
                            },
                          }),
                          _c("img", {
                            staticClass: "ctrl-s",
                            attrs: {
                              src: _vm.getImgSrc("btn-s", _vm.downGearBtn),
                            },
                          }),
                          _c("img", {
                            staticClass: "ctrl-n",
                            attrs: {
                              src: _vm.getImgSrc(
                                "btn-n",
                                _vm.neutralPositionBtn
                              ),
                            },
                          }),
                          _c("img", {
                            staticClass: "ctrl-p",
                            attrs: {
                              src: _vm.getImgSrc("btn-p", _vm.parkingBtn),
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "btn-v" }),
                        _c("div", { staticClass: "col-2-right" }, [
                          _c("div", { staticClass: "ctrl-jk" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.getImgSrc("btn-j", _vm.throttleBtn),
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: _vm.getImgSrc("btn-k", _vm.brakeBtn),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "btn-s" }, [
                          _c("img", {
                            staticClass: "ctrl-btn-right",
                            attrs: {
                              src: _vm.getImgSrc("btn-d", _vm.rightBtn),
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("img", {
                      staticClass: "ctrl-estop",
                      attrs: {
                        src: _vm.getImgSrc("btn-space", _vm.emergencyStop),
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "ctrl-right" }, [
                _c(
                  "div",
                  { staticClass: "ctrl-map" },
                  [
                    _c("map-page", {
                      ref: "mapRef",
                      attrs: { params: _vm.mapPageParams },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showModal
        ? _c(
            "div",
            { staticClass: "modal-tap", on: { click: _vm.modalClick } },
            [_vm._m(0)]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ratote-btn" },
        [
          _vm.type === "left" || _vm.type === "right"
            ? _c(
                "a-button",
                {
                  staticClass: "_btn",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleRatote },
                },
                [_vm._v("旋转画面")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _vm._v("----"),
      _c("span", { staticClass: "tip-text" }, [
        _vm._v("请点击任意地方进入视频画面"),
      ]),
      _vm._v("----"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }