var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "40%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          staticClass: "area-manager",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "路段名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.readOnly,
                  placeholder: "请输入路段名称",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "路段类型", prop: "markType" } },
            [
              _c(
                "div",
                { staticClass: "tip-icon" },
                [
                  _c(
                    "a-popover",
                    { attrs: { title: "提示", trigger: "hover" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("div", [
                          _vm._v("工作区：工作区围栏，作用于在岗情况"),
                        ]),
                        _c("div", [
                          _vm._v("停车场：停车区域围栏，作用于车辆停车"),
                        ]),
                        _c("div", [
                          _vm._v("禁行区：禁行区域围栏，车辆不能行驶的区域"),
                        ]),
                        _c("div", [
                          _vm._v(
                            "考勤区：考勤区域围栏，作用于小程序上下班打卡区域"
                          ),
                        ]),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/tip.png"),
                          alt: "",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "marktype-wrap",
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择路段类型", "allow-clear": "" },
                  model: {
                    value: _vm.form.markType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "markType", $$v)
                    },
                    expression: "form.markType",
                  },
                },
                _vm._l(_vm.markTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属部门", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.readOnly,
                  replaceFields: _vm.replaceFields,
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "路段范围", prop: "geomText" } },
            [
              _c("a-button", { on: { click: _vm.handleMapDraw } }, [
                _vm._v("在地图中绘制"),
              ]),
            ],
            1
          ),
          _c("a-form-model-item", { attrs: { label: "几何类型" } }, [
            _c("span", [
              _vm._v(_vm._s(_vm.geomTypeObj[_vm.form.geomType]) + " "),
            ]),
          ]),
          _c(
            "a-form-model-item",
            { attrs: { label: "开始日期" } },
            [
              _c("a-date-picker", {
                attrs: {
                  disabled: _vm.readOnly,
                  "value-format": "YYYY-MM-DD",
                  format: "YYYY-MM-DD",
                },
                model: {
                  value: _vm.form.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "startDate", $$v)
                  },
                  expression: "form.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "开始时间" } },
            [
              _c("a-time-picker", {
                attrs: {
                  disabled: _vm.readOnly,
                  "value-format": "HH:mm:ss",
                  format: "HH:mm:ss",
                },
                model: {
                  value: _vm.form.startTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "startTime", $$v)
                  },
                  expression: "form.startTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结束日期" } },
            [
              _c("a-date-picker", {
                attrs: {
                  disabled: _vm.readOnly,
                  "value-format": "YYYY-MM-DD",
                  format: "YYYY-MM-DD",
                },
                model: {
                  value: _vm.form.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "endDate", $$v)
                  },
                  expression: "form.endDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结束时间" } },
            [
              _c("a-time-picker", {
                attrs: {
                  disabled: _vm.readOnly,
                  "value-format": "HH:mm:ss",
                  format: "HH:mm:ss",
                },
                model: {
                  value: _vm.form.endTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "endTime", $$v)
                  },
                  expression: "form.endTime",
                },
              }),
            ],
            1
          ),
          _vm.form.markType === 1
            ? _c("a-form-model-item", { attrs: { label: "生效日" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "a-checkbox",
                      {
                        attrs: {
                          disabled: _vm.readOnly,
                          indeterminate: _vm.indeterminate,
                          checked: _vm.checkAll,
                        },
                        on: { change: _vm.handleWeekCheckAllChange },
                      },
                      [_vm._v(" 全选 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-checkbox-group", {
                      attrs: {
                        disabled: _vm.readOnly,
                        options: _vm.weekOptions,
                      },
                      on: { change: _vm.handleWeekChange },
                      model: {
                        value: _vm.checkedList,
                        callback: function ($$v) {
                          _vm.checkedList = $$v
                        },
                        expression: "checkedList",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.form.markType === 1
            ? _c(
                "div",
                { staticClass: "check-config" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("抽检时段"),
                    ]),
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("应在岗人数"),
                    ]),
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("时段内抽检次数"),
                    ]),
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("最小行走里程"),
                    ]),
                    _c("span", { staticClass: "delete-btn header" }),
                  ]),
                  _vm._l(_vm.samplingRules, function (item, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "span",
                        { staticClass: "col" },
                        [
                          _c("a-time-picker", {
                            attrs: {
                              disabled: _vm.readOnly,
                              width: 80,
                              "value-format": "HH:mm:ss",
                              format: "HH:mm:ss",
                            },
                            on: { change: _vm.handeStartTimeChange },
                            model: {
                              value: item.startTime,
                              callback: function ($$v) {
                                _vm.$set(item, "startTime", $$v)
                              },
                              expression: "item.startTime",
                            },
                          }),
                          _c("span", { staticClass: "connect-flag" }, [
                            _vm._v("-"),
                          ]),
                          _c("a-time-picker", {
                            attrs: {
                              disabled: _vm.readOnly,
                              width: 80,
                              "value-format": "HH:mm:ss",
                              format: "HH:mm:ss",
                            },
                            on: { change: _vm.handEndTimeChange },
                            model: {
                              value: item.endTime,
                              callback: function ($$v) {
                                _vm.$set(item, "endTime", $$v)
                              },
                              expression: "item.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "col" },
                        [
                          _c("a-input-number", {
                            attrs: { disabled: _vm.readOnly, min: 0 },
                            model: {
                              value: item.mustOnDutyCount,
                              callback: function ($$v) {
                                _vm.$set(item, "mustOnDutyCount", $$v)
                              },
                              expression: "item.mustOnDutyCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "col" },
                        [
                          _c("a-input-number", {
                            attrs: { disabled: _vm.readOnly, min: 0 },
                            model: {
                              value: item.samplingCount,
                              callback: function ($$v) {
                                _vm.$set(item, "samplingCount", $$v)
                              },
                              expression: "item.samplingCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "col" },
                        [
                          _c("a-input-number", {
                            attrs: { disabled: _vm.readOnly, min: 0 },
                            model: {
                              value: item.minWalkMileage,
                              callback: function ($$v) {
                                _vm.$set(item, "minWalkMileage", $$v)
                              },
                              expression: "item.minWalkMileage",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.readOnly
                        ? _c(
                            "span",
                            { staticClass: "delete-btn" },
                            [
                              _c("a-icon", {
                                staticClass: "item-delete",
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.form.markType === 1
            ? _c(
                "div",
                { staticClass: "add-btn" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "add",
                      attrs: { disabled: _vm.readOnly, type: "primary" },
                      on: { click: _vm.handleAddCheckTime },
                    },
                    [_c("a-icon", { attrs: { type: "plus" } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  !_vm.readOnly
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" " + _vm._s(_vm.readOnly ? "返回" : "取消") + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("road-scope-map", {
        ref: "roadScopeMapRef",
        attrs: { position: { top: "4%", left: "52%" } },
        on: { ok: _vm.handleOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }