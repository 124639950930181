<!--
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2023-09-14 18:30:47
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-11-14 14:23:22
 * @FilePath: \ra-web-admin\src\views\monitor\monitorForm\vehicle\funcList\modules\rtcScreen.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="rtc-screen"
    style="height: 100%; display: flex; background: #000; justify-content: center"
    :style="type !== 'main' ? ' align-items: center' : ''"
  >
    <div :id="brtcId" :class="rotateClass" :style="rotateStyle" v-if="videoType === 'brtc'"></div>
    <revo-video-player
      v-else
      @handleReady="handleReady"
      @handleError="handleError"
      :vehicleId="vehicleId"
      :vehicleCameraDeviceInfo="rtcInfo"
      videoType="rtc"
      :isLive="true"
      :showScreenBtn="true"
    />
    <div v-if="type === 'main'" class="ctrl-container" id="ctrlContainer">
      <div class="ctrl-left">
        <!-- 遥控实时信息 -->
        <div class="ctrl-info">
          <div class="speed">
            <img src="../../../../../../assets/images/control/bg-speed.png" height="100%" />
            <div class="speed-over">
              <span class="speed-text speed-media">{{ runningSpeed }}</span>
              <span class="speed-label speed-label-media">速度(KM/H)</span>
            </div>
          </div>
          <div class="rotate">
            <img src="../../../../../../assets/images/control/bg-direction.png" height="100%" />
            <div class="rotate-over">
              <img
                class="rotate-arrow"
                :style="{ transform: 'rotate(' + locationHeading + 'deg)' }"
                src="../../../../../../assets/images/control/ico-arrow.png"
              />
              <span class="rotate-text rotate-media">{{ locationHeading }}°</span>
              <span class="rotate-label rotate-label-media">方向{{ locationHeadingLRStr }}</span>
            </div>
          </div>
          <div class="info-content">
            <div class="soc" :style="leftLabelStyle">
              <span class="info-item">电量：{{ currentSoc }}%</span>
              <img
                :style="{ width: currentSoc + '%', height: '14px', marginTop: '4px' }"
                src="../../../../../../assets/images/control/bg-remainingPower.png"
              />
            </div>
            <div class="brake" :style="leftLabelStyle">
              <span class="info-item">刹车情况：{{ cmd.brake ? '刹车中' : '未刹车' }}</span>
            </div>
            <div class="state" :style="leftLabelStyle">
              <span class="info-item">作业状态： {{ workStatus }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ctrl-center">
        <div class="ctrl-btn">
          <div class="btn-list">
            <div class="row-1">
              <div class="col-1">
                <!-- <img @mousedown="turnLeftClickDown" @mouseup="turnLeftClickUp" class="ctrl-btn-left" src="../../../../../../assets/images/control/btn-a.png" /> -->
                <div class="btn-s">
                  <img class="ctrl-btn-left" :src="getImgSrc('btn-a', leftBtn)" />
                  <!-- <img
                      @mousedown="turnRightClickDown"
                      @mouseup="turnRightClickUp"
                      class="ctrl-btn-right"
                      :src="getImgSrc('btn-d', rightBtn)"
                    /> -->
                </div>
                <div class="btn-v">
                  <img class="ctrl-w" :src="getImgSrc('btn-w', upGearBtn)" />
                  <img class="ctrl-s" :src="getImgSrc('btn-s', downGearBtn)" />
                  <img class="ctrl-n" :src="getImgSrc('btn-n', neutralPositionBtn)" />
                  <img class="ctrl-p" :src="getImgSrc('btn-p', parkingBtn)" />
                </div>
              </div>

              <div class="col-2">
                <div class="btn-v">
                  <!-- <img @click="neutral" class="ctrl-n" :src="getImgSrc('btn-n', neutralPositionBtn)" />
                    <img @click="parking" class="ctrl-p" :src="getImgSrc('btn-p', parkingBtn)" /> -->
                </div>
                <div class="col-2-right">
                  <div class="ctrl-jk">
                    <img :src="getImgSrc('btn-j', throttleBtn)" />
                    <img :src="getImgSrc('btn-k', brakeBtn)" />
                  </div>
                </div>
                <div class="btn-s">
                  <img class="ctrl-btn-right" :src="getImgSrc('btn-d', rightBtn)" />
                </div>
              </div>
            </div>
            <img class="ctrl-estop" :src="getImgSrc('btn-space', emergencyStop)" />
          </div>
        </div>
      </div>
      <div class="ctrl-right">
        <div class="ctrl-map">
          <map-page ref="mapRef" :params="mapPageParams" />
        </div>
      </div>
    </div>
    <div class="modal-tap" v-if="showModal" @click="modalClick">
      <div class="tip">----<span class="tip-text">请点击任意地方进入视频画面</span>----</div>
    </div>
    <div class="ratote-btn">
      <a-button
        v-if="type === 'left' || type === 'right'"
        type="primary"
        size="small"
        class="_btn"
        @click="handleRatote"
        >旋转画面</a-button
      >
    </div>
  </div>
</template>

<script>
import RevoVideoPlayer from './RevoVideoPlayer'
import MapPage from '../../../../monitorComponents/mappage'
import get from 'lodash/get'
import { mapState } from 'vuex'
import { formatSpeed } from '@/utils/formatHelper'
import coordinateTransform from '@/utils/coordinateTransform'
import { getAllVehicleInfo, getOneVehicleInfo } from '@/api/iot/vehicle'
import BRtcHelper from './baiduRtc/BRtcHelper'
import { brtcHeartbeat } from '@/api/iot/cameraDevice'

export default {
  name: 'RtcScreen',
  components: {
    RevoVideoPlayer,
    MapPage
  },
  mixins: [BRtcHelper],
  computed: {
    ...mapState({
      allInfo: (state) => state.websocket.allInfo,
      websocketReady: (state) => state.websocketCmd.websocketCmdReady,
      ackData: (state) => state.websocket.ackData
    }),
    modalTitle() {
      if (!this.ackData.login) {
        // 正在与车端车接中
        return this.vehicleId + '-遥控模式' + ' 与车端连接中-遥控功能暂不可用'
      }
      const loginResult = this.ackData.login.result
      const cmdResult = this.ackData.cmd ? this.ackData.cmd.result : 0
      if (loginResult === 1 || cmdResult === 1) {
        return this.vehicleId + '-遥控模式' + ' 与车端重新连接中-遥控功能暂不可用'
      }

      if (loginResult > 2 || cmdResult > 2) {
        const msg = this.selectDictLabel(this.vehicleRemoteCtlResultOptions, loginResult > 2 ? loginResult : cmdResult)
        return this.vehicleId + '-遥控模式' + ' 车端当前不可被遥控，请稍后重新打开遥控功能，错误信息：' + msg
      }
      if (this.isSwitchAutoModeSuccess === false) {
        const msg =
          this.vehicleId + '-遥控模式 ' + (this.autoModeAction ? '车辆正在切换自动模式中' : '车辆正在切换手动模式中')
        return msg
      }
      return this.vehicleId + '-遥控模式'
    },

    // 速度
    runningSpeed() {
      const speed = formatSpeed(Number(get(this.info, 'runningState.speed', 0)))
      return speed ? speed.toFixed(2) : speed
    },
    // 方向
    locationHeading() {
      const headingVal = Number(get(this.info, 'location.heading', 0))
      return headingVal ? headingVal.toFixed(2) : headingVal
    },
    // 方向是左还是右
    locationHeadingLRStr() {
      const headingVal = Number(get(this.info, 'location.heading', 0))
      return headingVal === 0 ? '' : headingVal < 0 ? '(左)' : '(右)'
    },
    // 当前电量
    currentSoc() {
      const soc = Number(get(this.info, 'runningState.soc', 0))
      return soc ? soc.toFixed(2) : soc
    },
    // 车辆转角
    currentSteer() {
      const steer = Number(get(this.info, 'runningState.steer', 0))
      const steerStr = steer ? steer.toFixed(2) : steer
      if (steer > 0) {
        return '右' + steerStr
      } else if (steer < 0) {
        return '左' + steerStr
      } else {
        return steer
      }
    },
    // 作业状态
    workStatus() {
      // console.log('getWorkStatus')
      if (!this.taskStatusOptions) {
        return ''
      }
      const workStatus = this.ackData.businessState ? this.ackData.businessState.state : -1
      return this.selectDictLabel(this.taskStatusOptions, workStatus)
    },

    modalWidth() {
      let isMax = false
      if (this.$refs.vxeModal) {
        isMax = this.$refs.vxeModal.isMaximized()
      }
      return isMax ? this.screenWidth : this.screenWidth - this.position.left - 10
    }
  },
  watch: {
    allInfo(newVal, oldVal) {
      console.log('allInfo **:', this.allInfo)
      const tmpArr = newVal.map((item) => {
        let lng = 0
        let lat = 0
        if (item.location && item.location.longitude > 0 && item.location.latitude > 0) {
          lng = item.location.longitude
          lat = item.location.latitude
        } else if (item.ins) {
          lng = item.ins.longitude
          lat = item.ins.latitude
        }
        if (lng > 0 && lat > 0) {
          try {
            const coo = coordinateTransform.wgs84togcj02tobd09(lng, lat)
            return { ...item, ...coo, rotation: item.location ? item.location.heading : 0 }
          } catch (error) {
            return { ...item, lng: 0, lat: 0 }
          }
        } else {
          return { ...item, lng: 0, lat: 0 }
        }
      })
      // for (let k = tmpArr.length - 1; k >= 0; k--) {
      //   if (this.currentCheckedList.indexOf(tmpArr[k].vehicleId) < 0) {
      //     tmpArr.splice(k, 1)
      //   }
      // }
      this.recentObjectPointsObj.isUpdate = false
      // 地图更新
      this.recentObjectPointsObj.isMapUpdate = false
      this.recentObjectPointsObj.data = tmpArr
      console.log('recentObjectPointsObj', this.recentObjectPointsObj)
      this.info = this.recentObjectPointsObj.data[0]
      if (tmpArr.length > 0) {
        this.recentTime = tmpArr[0].time
      }
      if (!this.startTime) {
        this.startTime = this.recentTime
      }
      if (this.wsStartTime === 0) {
        this.wsStartTime = new Date().getTime()
      }
      this.totalTime = (new Date().getTime() - this.wsStartTime) / 1000
      ++this.wsCount
    },
    'info.inc'(newVal, oldVal) {
      console.log('info inc')
      this.handleUpdateMap()
    },
    'info.runningState'(newVal, oldVal) {
      this.setCurrentBtnStatus(newVal)
    },
    neutralPositionBtn(val) {
      console.log('watch:neutralPositionBtn-', val)
    }
  },
  data() {
    return {
      objectDelayUpdateInterVal: 0,
      // 定时器是否处理完毕
      isTimerDone: true,
      videoType: 'rtc',
      brtcId: '',
      isAdmin: false,
      signAdminVideoType: 'rtc',
      info: null,
      rtcInfo: {},
      vehicleId: '',
      typeMap: {
        main: '前',
        left: '左',
        right: '右'
      },
      emergencyStop: false,
      throttleBtn: false,
      brakeBtn: false,
      upBtn: false,
      downBtn: false,
      upGearBtn: false,
      downGearBtn: false,
      leftBtn: false,
      rightBtn: false,
      neutralPositionBtn: false,
      parkingBtn: false,
      cmd: {
        left: false,
        right: false,
        brake: false,
        throttle: false,
        slambrake: false,
        drive: false,
        neutral: false,
        reverse: false,
        automodeFlag: false,
        emergencyStop: false,
        throttleBtn: false,
        brakeBtn: false,
        upBtn: false,
        downBtn: false,
        upGearBtn: false,
        downGearBtn: false,
        leftBtn: false,
        rightBtn: false,
        neutralPositionBtn: false,
        parkingBtn: false,
        parking: false
      },
      recentObjectPointsObj: { isUpdate: true },
      mapPageParams: {
        // 是否更新地图，当打开其它窗口时，则不更新地图
        unShowDistance: true,
        isUpdateMap: true,
        showHeader: false,
        mapDelayUpdate: true,
        mapDelayUpdateInterval: 1000,
        objectId: 'vehicleId',
        infoWindowTitle: 'vehicleId',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'vehicle',
        // 地图数据源
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 车辆显示图标
        objectIcon: { url: require('@/assets/images/map/vehicle-sweeper.png'), size: { width: 80, height: 60 } },
        // 车辆离线图标
        objectIconOff: { url: require('@/assets/images/map/vehicle-off.png'), size: { width: 80, height: 60 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        // 路线规划
        laneDataArray: [],
        lushuConfig: {
          // playIcon: VehiclePlayIcon,
          // 不需要控制车辆方向
          enableRotation: true,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 52, height: 26 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 27, height: 14 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false,
        // 当前选中的轨迹对象
        currentTrackObject: null,
        // 正在连接车辆中...
        loadingWebsocket: false,
        loadingWebsocketMsg: '正在连线车辆，请稍后...',
        // 被选中的车移动距离（当前位置距离上一次更新的位置距离）超过多少米后才能移动位置并重置弹窗
        // 但弹窗信息必须实时更新
        selectedObjectMoveDistanceForResetLocation: 2,
        mapPanToOverDistance: 5,
        IsNotShowInfoWindow: true
      },
      showModal: false,
      rotateClass: '',
      rotateStyle: {
        height: '',
        width: ''
      },
      isRotate: false
    }
  },
  mounted() {
    if (this.videoType === 'brtc') {
      this.showModal = true
    }
    this.initTimer()
    this.handleUpdateMap()
    this.loadVehiclesMap()
    // this.subscribeManyVehicle([this.vehicleId], () => {
    //    console.log('allInfo:', this.allInfo)
    // })
  },
  created() {
    this.rtcInfo =
      this.$route.query.info && this.$route.query.info !== 'undefined' && JSON.parse(this.$route.query.info)
    this.brtc = this.$route.query.info && this.$route.query.info !== 'undefined' && JSON.parse(this.$route.query.info)
    this.type = this.$route.query.type
    this.videoType = this.$route.query.videoType
    this.vehicleId = this.$route.query.vehicleId
    this.brtcId = this.$route.query.brtcId
    const brtcUserIdMap = {
      main: 0,
      left: 1,
      right: 2
    }
    if (this.type === 'left') {
      // this.rotateClass = 'leftRotate'
      this.$nextTick(() => {
        const that = this
        this.setRotateStyle()
        window.addEventListener('resize', function () {
          // 页面宽度和高度可以通过window.innerWidth和window.innerHeight获取
          that.setRotateStyle()
        })
      })
    } else if (this.type === 'right') {
      // this.rotateClass = 'rightRotate'
      this.$nextTick(() => {
        const that = this
        this.setRotateStyle()
        window.addEventListener('resize', function () {
          // 页面宽度和高度可以通过window.innerWidth和window.innerHeight获取
          that.setRotateStyle()
        })
      })
    }
    if (this.videoType === 'brtc') {
      this.brtcUserIndex = brtcUserIdMap[this.type]
    }
    this.brtcHeartbeat()
    document.title = '摄像头-' + this.typeMap[this.type]
    localStorage.setItem('RTCSCREEN-' + this.type, new Date().getTime())
    this.timers = setInterval(() => {
      localStorage.setItem('RTCSCREEN-' + this.type, new Date().getTime())
    }, 300)
  },
  methods: {
    brtcHeartbeat() {
      brtcHeartbeat({
        brtcUserId: this.brtc.userTokens[this.brtcUserIndex].userId
      })
      setTimeout(() => {
        this.brtcHeartbeat()
      }, 4000)
    },
    setRotateStyle(isRotate) {
      var visibleHeight = window.innerHeight
      this.rotateStyle = {
        width: !isRotate ? '100%' : visibleHeight + 'px',
        transform:
          this.type === 'left'
            ? isRotate
              ? 'rotate(-90deg)'
              : ''
            : this.type === 'right'
            ? isRotate
              ? 'rotate(90deg)'
              : ''
            : ''
      }
    },
    modalClick() {
      this.showModal = false
      const that = this
      // 百度RTC初始化处理
      that.start(that.brtcId, that.brtcUserIndex)
    },
    getImgSrc(imgName, onVal) {
      console.log('imgName:', imgName)
      console.log('onVal:', onVal)
      if (onVal) {
        return require('@/assets/images/control/' + imgName + '-on.png')
      } else {
        return require('@/assets/images/control/' + imgName + '.png')
      }
    },
    handleUpdateMap() {
      console.log('handleUpdateMap', this.info)
      if (this.$refs.mapRef) {
        if (this.info) {
          this.$refs.mapRef.mapUpdateObjectPoints([this.info])
        } else {
          setTimeout(() => {
            this.handleUpdateMap()
          }, 1000)
        }
      }
    },
    async loadVehiclesMap() {
      if (!this.websocketReady) {
        // 建立socket连接
        await this.$store.dispatch('websocketConnect')
      }
      if (this.vehicleId) {
        if (!this.allInfoReady) {
          this.isGettingVehicleInfo = true
        }
        const params = {
          vehicleIds: this.vehicleId,
          selectVehicleId: this.vehicleId
        }
        console.log('http获取所有节点数据')
        getAllVehicleInfo(params).then((res) => {
          // 通过ws请求数据
          console.log(`http获取所有节点数据成功`)
          this.isGettingVehicleInfo = false
          this.$store
            .dispatch('websocketSubSend', {
              ids: [this.vehicleId],
              type: 'void',
              payload: {
                // 必需订阅
                mustSubs: true,
                type: 'all',
                data: res.data
              }
            })
            .then(() => {})
            .finally(() => {})
        })
      }
    },
    // 订阅多辆车
    subscribeManyVehicle(ids, callback) {
      this.isGettingVehicleInfo = true
      console.log(`http获取选中车辆全量数据,vehicleIds=${ids}`)
      getOneVehicleInfo({
        vehicleIds: ids.join(',')
      }).then((res) => {
        this.isGettingVehicleInfo = false
        console.log(`http获取所有节点数据成功`)
        this.$store.dispatch('websocketSubSend', {
          payload: {
            // 不需要订阅
            mustSubs: true,
            type: 'manyVehicle',
            data: res.data
          }
        })
        if (callback) {
          callback(res.data)
        }
      })
    },
    initTimer() {
      this.updateTimer = setInterval(() => {
        this.updateRecentObjectPoints()
      }, this.objectDelayUpdateInterVal)
    },
    updateRecentObjectPoints() {
      // 判断任务是否执行完成
      ++this.timerCount
      if (this.isTimerDone) {
        this.isTimerDone = false
        try {
          // 如果打开其它窗口，则地图节点分布与当前选中车辆的位置也不再更新
          if (this.mapPageParams.isUpdateMap === true) {
            // 允许地图更新状态
            if (this.mapPageParams.mapDataSource === 'real' && this.recentObjectPointsObj.isMapUpdate === false) {
              this.recentObjectPointsObj.isMapUpdate = true
              if (this.isFromTrackToReal) {
                // 如果是从轨迹播放页切换回实时状态页，则要求延迟处理地图刷新
                // 避免在地图切换过程中更新数据，导致地图渲染有问题
                // setTimeout(() => {
                //   this.doUpdate()
                // }, 100)
              } else {
                // this.doUpdate()
              }
              ++this.updateCount
            }
          } else {
            // 地图状态不允许更新
            if (this.mapPageParams.mapDataSource === 'real' && this.recentObjectPointsObj.isUpdate === false) {
              this.recentObjectPointsObj.isUpdate = true
              this.toSelectObject()
              ++this.updateCount
            }
          }

          // 处理直接跳转到轨迹页
          if (this.initSelectedKey && this.mapPageParams.mapDataSource === 'track') {
            if (this.recentObjectPointsObj.isUpdate === false) {
              if (!this.mapPageParams.currentTrackObject) {
                this.toSelectObject()
              }
            }
          } else if (this.mapPageParams.mapDataSource === 'track') {
            this.setTrackObjectInfo()
          }
        } finally {
          this.isTimerDone = true
        }
      }
    },
    setCurrentBtnStatus(val) {
      console.log('setCurrentBtnStatus:', val)
      if (!val) {
        return
      }
      if (val.throttle > 0) {
        // 踩油门
        this.throttleBtn = true
        this.brakeBtn = false
      }
      if (val.brake > 0) {
        // 刹车
        this.brakeBtn = true
        this.throttleBtn = false
      }
      if (val.throttle === 0 && val.brake === 0) {
        // 空档 或 park档
        this.brakeBtn = false
        this.throttleBtn = false
      }
      if (val.steer < 0) {
        // 左转
        this.leftBtn = true
        this.rightBtn = false
      } else if (val.steer > 0) {
        // 右转
        this.rightBtn = true
        this.leftBtn = false
      } else if (val.steer === 0) {
        // 直行
        this.leftBtn = false
        this.rightBtn = false
      }
      if (val.gear === '0') {
        // park 档
        this.parkingBtn = true
        this.upGearBtn = false
        this.downGearBtn = false
        this.neutralPositionBtn = false
      } else if (val.gear === '1') {
        // 倒挡
        this.parkingBtn = false
        this.upGearBtn = false
        this.downGearBtn = true
        this.neutralPositionBtn = false
      } else if (val.gear === '2') {
        // 空档
        this.parkingBtn = false
        this.upGearBtn = false
        this.downGearBtn = false
        this.neutralPositionBtn = true
      } else if (val.gear === '3') {
        // 前进档
        this.parkingBtn = false
        this.upGearBtn = true
        this.downGearBtn = false
        this.neutralPositionBtn = false
      }
      console.log('this.neutralPositionBtn', this.neutralPositionBtn)
      this.$forceUpdate()
    },
    disconnectSocket() {
      this.websocketReady = false
      this.$store.dispatch('stopWebSocket')
      this.isGettingVehicleInfo = false
    },
    handleRatote() {
      this.isRotate = !this.isRotate
      this.setRotateStyle(this.isRotate)
    }
  },
  beforeDestroy() {
    this.updateTimer && clearInterval(this.updateTimer)
    this.timers && clearInterval(this.timers)
    this.disconnectSocket()
  },
  destroyed() {}
}
</script>

<style lang="less" scoped>
.rtc-screen {
  overflow: hidden;
}
.ctrl-container {
  margin: auto;
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  position: absolute;
  // left: 50%;
  top: 0;
  // transform: translate(-50%, -50%);

  //border: 1px solid red;
  // display: flex;
  .ctrl-left,
  .ctrl-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    //min-width: 310px;
    .side-camera {
      min-height: 180px;
      height: 30%;
    }
  }
  .ctrl-left {
    position: absolute;
    top: 0;
    background: transparent;
    z-index: 100;
  }
  .ctrl-center {
    flex: 2;
    //min-width: 660px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .main-camera {
      min-height: 370px;
      height: 100%;
    }
    .ctrl-btn {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-top: 10px;
      position: absolute;
      bottom: 0;
      opacity: 0.4;
      width: 100%;
      height: 300px;
      z-index: 100;
      .btn-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        .row-1 {
          display: flex;
          justify-content: space-between;
          .col-1 {
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 20px;
            bottom: 35px;
          }
          .col-2 {
            display: flex;
            justify-content: space-between;
            position: absolute;
            right: 20px;
            bottom: 35px;
            .col-2-right {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              margin-left: 15px;
              .ctrl-mode {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .ctrl-mode-text {
                  color: #067b7a;
                }
                .ctrl-mode-text-grey {
                  color: #3a3a3a;
                }
              }
              .ctrl-jk {
                display: flex;
                justify-content: space-between;
                img {
                  margin-left: 15px;
                }
              }
            }
          }
        }
        .ctrl-estop {
          margin-top: 20px;
          position: absolute;
          left: 50%;
          margin-left: -263px;
          bottom: 35px;
        }
      }
      .btn-v {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 15px;
        align-items: flex-end;
        img {
          margin-right: 15px;
          height: 80px;
        }
      }
      .ctrl-btn-right {
        margin-left: 15px;
      }
    }
  }
  .side-camera {
    margin-bottom: 10px;
  }
  .ctrl-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 200px;
    z-index: 100;
    .ctrl-map {
      flex: 1;
      border-radius: 10px;
      // margin-top: 10px;
      background-color: #0e1116;
    }
  }
  .ctrl-left {
    .ctrl-info {
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 10px;
      padding-bottom: 10px;
      opacity: 0.6;
      width: 300px;
      height: 540px;
      position: absolute;
      background: #66666670;
      .info-item {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      .speed {
        position: relative;
        margin-top: 25px;
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .speed-over {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .speed-text {
            font-size: 30px;
            font-weight: 700;
            color: #fff;
          }
          .speed-label {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .rotate {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 100%;
        .rotate-arrow {
          position: absolute;
          transform-origin: 50% 50%;
          z-index: 0;
        }
        .rotate-over {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .rotate-text {
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            z-index: 1;
          }
          .rotate-label {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            z-index: 1;
          }
        }
      }
      .info-content {
        margin-top: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .soc {
        color: #fff;
        margin-top: 20px;
        background: url('../../../../../../assets/images/control/bg-power.png') no-repeat;
        height: 40px;
        background-position: bottom;
        display: flex;
        flex-direction: column;
      }
      .brake,
      .state {
        color: #fff;
        margin-top: 20px;
        background: url('../../../../../../assets/images/control/bg-info.png') no-repeat;
        height: 30px;
        background-position: bottom;
        background-size: 100% auto;
      }
      .state {
        margin-bottom: 20px;
      }
    }
  }
}
.modal-tap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .tip {
    color: #fff;
    animation-name: fade;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    .tip-text {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.leftRotate {
  transform: rotate(-90deg);
}

.rightRotate {
  transform: rotate(90deg);
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.ratote-btn {
  position: fixed;
  right: 10px;
  top: 10px;
  ._btn {
  }
}
</style>
